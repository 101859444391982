<template>
  <div class="grid grid-cols-1 container m-0 min-w-full">

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Bestellung </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
         Wenn Du an einer Bestellung interessiert bist, kannst Du mich einfach per E-Mail oder mit dem Kontaktformular kontaktieren. Dann besprechen wir genauer, wie dein Bild aussehen soll, wo der Preis liegt und bis wann das Bild fertig ist. Wenn es aufgrund eines Geburtstags oder ähnliches Zeitdruck gibt, kann ich gerne versuchen, das Bild dementsprechend fertig zu stellen. 
        </div>
      </div>
    </div>

    <div class="row-span-1 h-40 md:h-96 bg-order bg-cover order insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Vorlage </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
         Es gilt:<br> Je besser die Vorlage, desto besser das Endprodukt. Ich kann auch mehrere Bilder in eine Vorlage bearbeiten. Ich bearbeite dein Foto in einem Programm, um zu sehen, ob Dir die gewählte Hintergrundfarbe und die Proportionen gefallen. So soll dann das Bild am Ende aussehen!
        </div>
      </div>
    </div>
    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Bezahlung </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
        Wenn Du mit deiner Vorlage zufrieden bist, starte ich mit der Zeichnung, wenn 25 % des vereinbarten Preises überwiesen wurden. Mit der Anzahlung kann ich sicher gehen, dass tatsächlich Interesse an dem Auftrag besteht. Sollte der Auftrag während der Bearbeitung von Seite des Kunden abgebrochen werden, wird der Betrag nicht zurückerstattet. Kommt es von meiner Seite aus nicht zu der Fertigstellung des Auftrags, wird die Anzahlung natürlich zurückerstattet. Sobald der Auftrag fertig gestellt wurde, können noch kleine Korrekturen vorgenommen werden. Wenn der Kunde zufrieden ist, wird der Rest des Preises überwiesen. 
        </div>
      </div>
    </div>
    
    <div class="row-span-1 h-40 md:h-96 bg-cover bg-template template insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Dauer </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          Mit dem Eingang der Anzahlung wird eine ungefähre Bearbeitungsdauer angegeben, mit der Du rechnen kannst. Ich kann auf Nachfrage hin Fotos von dem Fortschritt schicken, wenn das gewünscht ist. 
        </div>
      </div>
    </div>

    <div class="row-span-1 h-40 md:h-96 bg-duration bg-cover duration insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Versand </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          Die fertige Zeichnung wird in einem Plastikumschlag verpackt, um das Bild zu schützen. Dieser wird dann zusammen mit Pappe für zusätzliche Stabilität in einem Luftpolsterumschlag verpackt. Der Versand innerhalb Deutschlands ist inklusive. Bei Bestellungen aus dem Ausland sind von dem Kunden zusätzliche Versandkosten zu übernehmen. Nach Rücksprache, zum Beispiel bei größeren Aufträgen, kann das Paket auch versichert werden. Gerne ist auch nach Absprache eine Abholung möglich. 
        </div>
      </div>
    </div>

    <div class="row-span-1 h-40 md:h-96 bg-delivery bg-cover delivery insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-5xl text-left">Preise </h1>
        <div class="h-20"></div>
        <h1 class="text-4xl text-left"> Versand  </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
         Die Versandkosten sind innerhalb Deutschlands inklusive.
        </div>
        <div class="h-20"></div>
        <h1 class="text-4xl text-left"> Portraits  </h1>
        <hr class="border-1 divide-gray-500 rounded my-4">
        <div class="text-2xl text-justify leading-relaxed">
          Die Portraits werden auf stabilem Papier (300 g/m2), das in mehreren Farben angeboten wird, angefertigt. Zum Zeichnen werden hauptsächlich Polychromos von Faber-Castell verwendet, die hochwertige Pigmente enthalten und eine hervorragende Lichtbeständigkeit und Farbbrillianz aufweisen, damit dein Bild möglichst lange sehr gut erhalten bleibt.  
        </div>
      </div>
    </div>

    <div class="row-span-1 h-40 md:h-96 bg-prices bg-cover prices insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  mx-10 md:mx-60">
        <h1 class="text-4xl text-left"> Preise  </h1>
        <div class="grid grid-cols-3 grid-rows-3 text-2xl">
          <div>A5</div><div>(14,8 x 21 cm)</div><div>75€</div>
          <div>A4</div><div>(21 x 29,7 cm)</div> <div>120€</div>
          <div>A3</div><div>(29,7 x 42 cm)</div><div>180€</div>
        </div>
        <div class="h-10"></div>
        <div class="text-2xl text-justify leading-relaxed">
          Andere Größen können nach Absprache zugeschnitten werden. Der genaue Preis wird dann abhängig von der gewählten Größe geklärt.  
        </div>
      </div>
    </div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-start min-w-full  mx-10 md:mx-60">
        <h1 class="text-4xl px-10 md:px-60 text-left"> Zusätzliche Motive</h1>
        <div class="text-2xl text-justify leading-relaxed">
        </div>
      </div>
    </div>

    <div class="row-span-1 h-40 md:h-96 bg-partner bg-cover partner insetShadow"></div>

    <div class="flex bg-paper bg-cover min-w-full justify-center py-14">
      <div class="place-self-center  min-w-full mx-10 md:mx-60">
        <div class="text-2xl px-10 md:px-60  text-justify leading-relaxed">
          Die Preise sind für jeweils ein Tier. Für jedes weitere Tier kommt ein Aufpreis von 20 % hinzu.
        </div>
      </div>
    </div>

  </div>
</template>

<script>

//import {showAt} from 'vue-breakpoints'

export default {
  name: 'Work',
  components: {  },
  props: {
    
  },
  data() {
        return {
          WorkflowDescriptor: {
            order:{
              Header: 'Bestellung',
              PictureUrl: '',
              Text: 'Wenn Du an einer Bestellung interessiert bist, kannst Du mich einfach per E-Mail oder mit dem Kontaktformular kontaktieren. Dann besprechen wir genauer, wie dein Bild aussehen soll, wo der Preis liegt und bis wann das Bild fertig ist. Wenn es aufgrund eines Geburtstags oder ähnliches Zeitdruck gibt, kann ich gerne versuchen, das Bild dementsprechend fertig zu stellen.'
            }
          }
        };
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>




.order{
  background-position: 0% 50%;
  
}

.template{
  background-position: 20% 50%;
}

.duration{
  background-position: 0% 50%;
}

.delivery{
  background-position: 0% 50%;
}

.prices{
  background-position: 0% 35%;
}

.partner{
  background-position: 0% 55%;
}


</style>

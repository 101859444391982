<template>
<div class="flex lg:mx-60" id="galerycontainer">
  <div class="grid grid-cols-1 md:grid-cols-4 gap-4" :key="updated">
      
      <h1 v-if="!updated">Vue is awesome!</h1>

      <div v-for="(image, index) in img" :key="index" :class="image.class" class="overflow-hidden">
          <img :src="image.img.src" class="p-4"/>
      </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Galery',
  data() {
    return {
      img: [],

      updated: false
    };
  },
  created: function() {
  let tmpImg= this.importAll(require.context('../assets/img/galery/', true, /\.(png|jpe?g|svg|webp)$/));
  tmpImg.forEach(element => {
    
    let tmp = new Image();
    tmp.loading="lazy"
    tmp.src = element
    tmp.classList.add("p-4")
    
    let w = 10 * Math.ceil(tmp.naturalWidth / 10);
    let h = 10 * Math.ceil(tmp.naturalHeight / 10);
    let r = this.gcd(w, h)

    var x = {
      'img': tmp,
      'class': this.setClass((w/r) - (h/r)),
    }
    this.img.push(x)
    
  });

  
  },
  mounted(){
    this.$forceUpdate();
    
  },
  beforeUpdate(){
  },
  updated(){
    console.log("debug flag")

    this.updated = true;
  },
  methods: {
    setClass(ratio){
      if(ratio > 0){
        return 'landscape'
      }if (ratio < 0) {
        return 'portrait'
      } else {
        return 'portrait'
      }
    },
    gcd (a,b) {
      return (b == 0) ? a : this.gcd (b, a%b);
    },
    importAll(r) {
       return  r.keys().map(r);
    },
  },
};
</script>

<style>

.landscape{
   grid-column: span 2;
}

.portrait{
  grid-column: span 1;
  grid-row: span 1;
}

</style>
